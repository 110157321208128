import { UserMedia, Waveform, Transport } from 'tone'
import { pitchDetection }                 from 'framework/models/PitchDetection'

export class PitchDetectionKeeper {
  constructor() {
    this.detections = []
    this.detect     = this.detect.bind(this)
  }

  startRecording() {
    this.waveform  = new Waveform(2048)
    this.userMedia = new UserMedia()
    this.userMedia.connect(this.waveform)
    this.userMedia.open().then(() => {
      this.isMicrophoneBlocked = false
      this.rafId               = requestAnimationFrame(this.detect)
    }).catch(() => {
      this.isMicrophoneBlocked = true
    })
  }

  detect() {
    if(this.userMedia.state === 'started') {
      this.addDetection(pitchDetection.detect(this.waveform.getValue()))
    }
    this.rafId = setTimeout(this.detect, 60)
  }

  addDetection(frequency) {
    this.detections.push({
      frequency,
      time: Transport.seconds
    })
  }

  reset() {
    this.waveform.dispose()
    this.waveform = new Waveform(2048)
    this.userMedia.connect(this.waveform)
    this.detections.length = 0
    this.userMedia.close()
    cancelAnimationFrame(this.rafId)
  }

  getDetections() {
    return [...this.detections]
  }

  dispose() {
    this.reset()
    this.userMedia.dispose()
  }
}

export const pitchDetectionKeeper = new PitchDetectionKeeper()