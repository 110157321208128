import store            from 'src/store'
import { RANGE_END }    from 'misc/app-constants'
import { noteToObject } from 'framework/utilities/Utilities'
import { isValidPitch } from 'framework/helpers/note-art-helpers'

export function setupExerciseByQuery({ name, preHarmony, intervals = '' }) {
  name       = name || 'Custom'
  preHarmony = preHarmony ? preHarmony.split(',') : [0]
  intervals  = intervals.split(',')

  return !intervals ? false : {
    name,
    preHarmony,
    intervals
  }
}

export function updateContextByQuery({ from, to }) {
  if(isValidPitch(from) && isValidPitch(to)) {
    const { pitchClass: pc1, octave: oct1 } = noteToObject(from)
    const { pitchClass: pc2, octave: oct2 } = noteToObject(to)
    store.dispatch('context/setPitchClass', { pitchClass: pc1 })
    store.dispatch('context/setPitchClass', { key: RANGE_END, pitchClass: pc2 })
    store.dispatch('context/setOctave', { octave: oct1 })
    store.dispatch('context/setOctave', { key: RANGE_END, octave: oct2 })
  } else {
    return false
  }
}

export function updateSettingsByQuery({ bpm, interactive, time, analyze }) {
  bpm         = parseInt(bpm) || 60
  time        = parseInt(time)
  interactive = !!(interactive && interactive === 'true')
  analyze     = !!(analyze && analyze === 'true')

  store.dispatch('exercises/setAnalyze', analyze)
  store.dispatch('transport/setBPM', bpm)
  store.dispatch('exercises/setIsInteractive', interactive)
  if(time) {
    store.dispatch('exercises/setIsTimerActive', true)
    store.dispatch('exercises/setTimerMs', time)
  } else {
    store.dispatch('exercises/setIsTimerActive', false)
  }
}