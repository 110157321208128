import audioManager     from 'framework/models/AudioManager'
import store            from 'src/store'
import { Loop }         from 'tone'
import { DRUMS_PLAYER } from 'framework/resources/framework-constants'


/**
 * Creates a simple Tone.Loop that plays every quarter note.
 */
export function scheduleStaticMetronome() {
  const callback = (time) => {
    if(store.getters['metronome/getIsMetronomeActive']) {
      const notes = [store.getters['metronome/getMetronomeSound']]
      audioManager[DRUMS_PLAYER].play({ notes, time })
    }
  }
  const loop     = new Loop(callback, '4n')
  loop.start()
}
