<template>
  <div>
    <exercise-results v-if="getAnalyze && exerciseState === 'finished' && graphData.detections"
                      :graph-data="graphData"
                      @finish-exercise="finishExercise"
                      @restart-exercise="startExercise"/>
    <div v-else>
      <div class="pa-4">
        <h1 class="display-1">
          {{ exercise ? exercise.name : 'Pitch Practice' }}
        </h1>
      </div>
      <div v-if="isStartState" class="pa-4">
        <v-btn :block="$vuetify.breakpoint.xsOnly" color="success"
               @click="startExercise">
          Start
<!--          <app-icon icon="play-arrow"/>-->
        </v-btn>
        <div class="py-2"/>
        <v-btn small :block="$vuetify.breakpoint.xsOnly" color="warning" class="text-none" @click="$router.back()">
          Back
        </v-btn>
      </div>
      <div v-else class="pa-4">
        <div class="d-inline-block text-sm-right px-sm-2">
          <v-btn :block="$vuetify.breakpoint.xsOnly" color="error darken-2"
                 @click="stopExercise">
            Stop
            <app-icon icon="stop"/>
          </v-btn>
        </div>
        <div v-if="!isStartState" class="d-inline-block text-sm-left px-sm-2">
          <v-btn :block="$vuetify.breakpoint.xsOnly" :color="!getIsTransportPaused ? 'warning' : 'success'"
                 @click="togglePause">
            {{ !getIsTransportPaused ? 'Pause' : 'Resume' }}
            <app-icon :icon="getIsTransportStarted ? 'pause' : 'play-arrow'"/>
          </v-btn>
        </div>
      </div>
      <div class="pa-4">
        <div class="text-center px-0">
          <app-piano/>
        </div>
      </div>
      <div class="pa-4">
        <v-btn small class="secondary text-none" @click="optionsDialog=true">
          Exercise Options -
          <app-icon icon="settings" size="18"/>
        </v-btn>
      </div>
    </div>
    <v-col :cols="12"
           @click="copyExerciseUrl"
           :class="`font-weight-regular pa-4 ${$vuetify.breakpoint.mdAndUp ? 'body-1' :'body-2'}`"
           style="cursor: pointer;">
      <span style="border-bottom: solid 1px">
        Click here to Get a direct link to this exercise
        <app-icon icon="send" :size="12"/>
      </span>
    </v-col>
    <v-dialog v-model="optionsDialog" max-width="350">
      <app-dialog @close="optionsDialog=false">
        <template #title>Exercise Options</template>
        <v-row>
          <v-col cols="12" align="center">
            <div class="py-6">
              <app-slider thumb-label="always" value-name="BPM" :initial-value="getTransportBPM" :min="40" :max="250"
                          @value-changed="bpmUpdate">
                <v-btn :small="$vuetify.breakpoint.mdAndDown"
                       color="secondary">
                  BPM: {{ getTransportBPM }}
                </v-btn>
              </app-slider>
            </div>
          </v-col>
          <v-col align="center">
            <v-switch hide-details v-model="isMetronomeActive"
                      label="Metronome"
                      color="accent"
                      class="pa-0 ma-0"
                      style="display: inline-block;"/>
          </v-col>
        </v-row>
      </app-dialog>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters }                                            from 'vuex'
import { playTracker }                                                       from 'misc/globals'
import { pitchDetectionKeeper }                                              from 'models/PitchDetectionKeeper'
import { RANGE_END }                                                         from 'misc/app-constants'
import { scheduleExercise, schedulePitchPractice }                           from 'utilities/player-helpers'
import { scheduleStaticMetronome }                                           from 'framework/helpers/audio-playing/metronome'
import { setupExerciseByQuery, updateContextByQuery, updateSettingsByQuery } from 'utilities/route-handler'
import { copyTextToClipboard }                                               from 'framework/utilities/Utilities'
import AppDialog                                                             from 'framework/ui/components/AppDialog'

export default {
  name:       'Exercise',
  components: {
    AppDialog,
    AppPiano:        () => import('framework/ui/components/piano/AppPiano'),
    ExerciseResults: () => import('src/sections/ExerciseResults'),
    AppSlider:       () => import('framework/ui/components/AppSlider')
  },

  mounted() {
    const query          = this.$route.query
    const isValidContext = updateContextByQuery(query)
    updateSettingsByQuery(query)
    if(isValidContext === false) {
      this.exercise = false
    }
    this.exercise = query.intervals ? setupExerciseByQuery(query) : null
  },

  destroyed() {
    if(this.getIsTransportStarted) {
      this.stopPlayback()
    }
  },

  data() {
    return {
      exercise:      null,
      exerciseState: 'ready',
      graphData:     [],
      optionsDialog: false
    }
  },

  computed: {
    ...mapGetters('transport', ['getIsTransportStarted', 'getIsTransportPaused', 'getTransportBPM']),
    ...mapGetters('metronome', ['getIsMetronomeActive']),
    ...mapGetters('context', ['getPitch']),
    ...mapGetters('exercises', ['getTimerMS', 'getIsInteractive', 'getIsTimerActive', 'getAnalyze']),

    isMetronomeActive: {
      get() {
        return this.getIsMetronomeActive
      },
      set(active) {
        this.setMetronome({ active })
      }
    },

    isStartState: function() {
      return this.exerciseState === 'ready'
    }
  },

  methods: {
    ...mapActions('transport', [
      'startPlayback', 'setBPM', 'pauseTransport', 'resumeTransport', 'stopPlayback'
    ]),
    ...mapActions('metronome', ['setMetronome']),

    toggleExercise() {
      this.getIsTransportStarted ? this.stopExercise() : this.startExercise()
    },

    togglePause() {
      this.getIsTransportPaused ? this.resumeExercise() : this.pauseExercise()
    },

    stopExercise() {
      this.stopPlayback()

      if( !this.getAnalyze) {
        this.exerciseState = 'ready'
      } else {
        this.graphData = {
          exercise:   [...playTracker],
          detections: pitchDetectionKeeper.getDetections()
        }
        pitchDetectionKeeper.reset()
        playTracker.length = 0
        this.exerciseState = 'finished'
      }
    },

    startExercise() {
      this.exerciseState = 'started'
      this.exercise ? this.startNormalExercise() : this.startPitchPracticeExercise()
      scheduleStaticMetronome()
      this.startPlayback()
      if(this.getAnalyze) {
        pitchDetectionKeeper.startRecording()
      }
      if(this.getIsTimerActive) {
        setTimeout(this.stopExercise, this.getTimerMS)
      }
    },

    startNormalExercise() {
      const startNote     = this.getPitch()
      const endNote       = this.getPitch(RANGE_END)
      const exercise      = this.exercise
      const steps         = [...Array(40).keys()].map(i => i + 1)
      const isInteractive = this.getIsInteractive
      scheduleExercise({ startNote, endNote, exercise, steps, isInteractive })
    },

    startPitchPracticeExercise() {
      const startNote     = this.getPitch()
      const endNote       = this.getPitch(RANGE_END)
      const isInteractive = this.getIsInteractive
      schedulePitchPractice({ startNote, endNote, isInteractive })
    },

    pauseExercise() {
      this.pauseTransport()
    },

    resumeExercise() {
      this.resumeTransport()
    },

    bpmUpdate(val) {
      this.setBPM(val)
    },

    finishExercise() {
      const route = this.exercise ? 'exercises' : 'pitch-practice'
      this.$router.push(route)
    },

    async copyExerciseUrl() {
      const url     = window.location.href
      const didCopy = await copyTextToClipboard(url)
      didCopy ?
      await this.$store.dispatch(
          'view/setSnackbar',
          { text: `Exercise url copied to clipboard!`, timeout: 4000, active: true }
      ) :
      await this.$store.dispatch(
          'view/setDialog',
          {
            title:  'Copying failed',
            body:   `Copying to clipboard failed, please do it manually: ${ url }`,
            active: true
          }
      )
    }
  }
}
</script>

<style scoped>

</style>
